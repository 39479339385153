import {
    ErrorFlashMessages,
    FlashMessagesProvider,
    SemanticsProvider,
    SuccessFlashMessages,
    useFlashMessages,
} from '@ollico/optiiva-ui-sdk';
import { explodeFlatErrors, FormHelpers } from '@ollico/optiiva-ui-sdk/lib/components/FormProvider';
import { ToastContainer } from 'react-toastify';
import { ReferralForm } from '@ollico/optiiva-ui-sdk/lib/components/SLHH';
import { ReferralFormData } from '@ollico/optiiva-ui-sdk/lib/components/SLHH/ReferralForm/ReferralForm';
import { CenteredSpinner } from '@ollico/optiiva-ui-sdk/lib/components/Spinner';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';

const ReferralFormBody = () => {
    const { flashError, flashSuccess } = useFlashMessages();

    const onSubmit = (
        data: ReferralFormData,
        form: FormHelpers<ReferralFormData>,
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const { first_name, last_name, ...payload } = data;

            const newData = {
                ...payload,
                first_name,
                last_name,
                source_id: process.env.REACT_APP_SOURCE_ID,
                project_id: 'a176940c-a732-4e29-beb8-5b9442ed13f4',
            };

            Axios.post(process.env.REACT_APP_REFERRAL_URL as string, newData)
                .then(() => {
                    setTimeout(() => {
                        flashSuccess('Referral submitted');
                        form.resetForm();
                        window.scrollTo({ top: 0 });
                        resolve();
                    }, 250);
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    flashError(error.response.data.message);
                    window.scrollTo({ top: 0 });
                    if (error.response.status === 422) {
                        form.setErrors(explodeFlatErrors(error.response.data.errors));
                    }
                    reject();
                });
        });
    };

    return <ReferralForm errors={{}} onSubmit={onSubmit} />;
};

const SLHHReferralForm = (): JSX.Element => {
    const [loading, setLoading] = useState(true);
    const [lang, setLang] = useState({});

    useEffect(() => {
        Axios.post(process.env.REACT_APP_SEMANTICS_URL as string).then((res) => {
            setLang(JSON.parse(res.data));
            setLoading(false);
        });
    }, []);

    return loading ? (
        <div className="h-screen w-full bg-blue-lighter">
            <CenteredSpinner inverted />
        </div>
    ) : (
        <SemanticsProvider lang={lang}>
            <FlashMessagesProvider>
                <ReferralFormBody />
                <ToastContainer />
                <SuccessFlashMessages />
                <ErrorFlashMessages />
            </FlashMessagesProvider>
        </SemanticsProvider>
    );
};

export default SLHHReferralForm;
